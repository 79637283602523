import { RiotPlatformRegion } from "~/types/riot";

export const getRegionName = (region: RiotPlatformRegion): string => {
  return {
    [RiotPlatformRegion.GLOBAL]: "Global",
    [RiotPlatformRegion.BRAZIL]: "Brazil",
    [RiotPlatformRegion.EUROPE_NORTH]: "Europe North",
    [RiotPlatformRegion.EUROPE_WEST]: "Europe West",
    [RiotPlatformRegion.JAPAN]: "Japan",
    [RiotPlatformRegion.KOREA]: "Korea",
    [RiotPlatformRegion.LATIN_AMERICA_NORTH]: "Latin America North",
    [RiotPlatformRegion.LATIN_AMERICA_SOUTH]: "Latin America South",
    [RiotPlatformRegion.MIDDLE_EAST]: "Middle East",
    [RiotPlatformRegion.NORTH_AMERICA]: "North America",
    [RiotPlatformRegion.OCEANIA]: "Oceania",
    [RiotPlatformRegion.TURKEY]: "Turkey",
    [RiotPlatformRegion.RUSSIA]: "Russia",
    [RiotPlatformRegion.PHILIPPINES]: "Philippines",
    [RiotPlatformRegion.SINGAPORE]: "Singapore",
    [RiotPlatformRegion.THAILAND]: "Thailand",
    [RiotPlatformRegion.TAIWAN]: "Taiwan",
    [RiotPlatformRegion.VIETNAM]: "Vietnam"
  }[region];
};